@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

@layer tailwind-base, primevue, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

$primaryColor: #e52323;

:root {
  --primary-color: #e83a3a;
  --primary-color-hover: #e52323;
  --primary-900: #FF6F61;
  --primary-600: #ff9387;
  --primary-300: #ffbeb6;
  --primary-100: #fdddd8;

  --text-color: rgb(51, 65, 85);
  --text-color-secondary: #002147;

  --font-family: 'Roboto', sans-serif;
  --font-size: 16px;

  --p-tag-font-size: 0.75rem;
  --p-tag-padding: 0.15rem 0.3rem;
  --p-badge-secondary-color: #e83a3a;
  --p-badge-secondary-background: #fdddd8;
  --p-fieldset-color: var(--primary-color);

  --vc-nav-background: rgba(232, 58, 58, 0.1);
  --vc-nav-border-radius: 15px;
  --vc-nav-color: var(--primary-color);
}

@layer base {
  h1 {
    @apply text-2xl;
    @apply font-extrabold;
    @apply my-5;
  }
  h2 {
    @apply text-xl;
    @apply font-extrabold;
    @apply my-5;
  }
  h3 {
    @apply text-lg;
    @apply font-extrabold;
    @apply my-5;
  }
}

html {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}

body {
  background-color: #f8fafc;
  color: var(--text-color);
  font-weight: 400;
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: var(--font-family) !important;
  //font-feature-settings: var(--font-feature-settings) !important;
  font-size: var(--font-size) !important;
}

.layout-wrapper {
  min-height: 100vh;
  padding-top: 56px;
}

.layout-header-wrapper {
  min-height: 156px;
  background-color: var(--primary-600);
}

.layout-header {
  background-color: var(--primary-900);
}

.layout-header-social {
  min-height: 33px;
  color: var(--primary-color-text);
  background-color: #002147;
}

.layout-header-social .p-button-text:not(:disabled):hover {
  background-color: transparent;
}

.layout-header-menu {
  background-color: var(--primary-600);
  height: 48px;

  .p-megamenu-item:not(.p-disabled) > .p-megamenu-item-content:hover {
    background-color: transparent;
  }

  .p-megamenu-submenu-label {
    display: none;
  }

  .p-megamenu {
    background-color: transparent;
    padding: 0.25rem 0;
    border: 0;
    border-radius: 0;

    .p-menuitem.p-focus,
    .p-menuitem.p-focus .p-menuitem-content {
      background: transparent;
    }

    .p-menuitem.p-highlight > .p-menuitem-content {
      background-color: var(--primary-color-hover);
      color: var(--primary-color-text);
    }

    .p-menuitem {
      margin: 0;
    }

    .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
      color: #002147;
    }

    .p-megamenu-root-list > .p-menuitem > .p-menuitem-content a {
      color: #002147;
      text-decoration: none;
    }

    .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-content a {
      color: var(--primary-color-text);
    }

    .p-megamenu-root-list > .p-menuitem > .p-menuitem-content:hover {
      background-color: var(--primary-color-hover);
    }

    .p-megamenu-root-list > .p-menuitem > .p-menuitem-content:hover a {
      color: var(--primary-color-text);
    }

    .p-megamenu-submenu > .p-menuitem > .p-menuitem-content:hover {
      background-color: transparent;
    }
  }
}

.layout-footer {
  @apply text-gray-300;
  background-color: #002147;
}

.layout-footer ul {
  padding: 0;
}

.layout-footer li {
  margin-top: .5rem;
}

.layout-footer a {
  color: var(--gray-300);
  text-decoration: none;
}

.layout-footer a:hover {
  color: var(--primary-color);
}

.layout-header-breadcrumb {
  background-color: var(--primary-100);

  .p-breadcrumb {
    padding: 0;
    background-color: var(--primary-100);
    min-height: 24px;
    vertical-align: middle;
    line-height: 24px;

    .p-breadcrumb-item-link {
      min-height: 24px;
      line-height: 24px;
    }

    .p-breadcrumb-list {
      white-space: nowrap;
    }

    .p-menuitem:hover span {
      color: var(--primary-color);
    }
  }
}

.layout-header section,
.layout-header-breadcrumb section,
.layout-main > section,
.layout-footer section {
  max-width: 1500px;
  margin: auto !important;
  padding: 0 1rem;
}

.layout-main {
  background-color: var(--surface-ground);

  section {
    //padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    a:not(.p-button) {
      color: var(--primary-color);
      text-decoration: none;
    }

    a.text-black {
      color: #000 !important;
    }

    p, li {
      line-height: 1.75rem;
      //font-size: 1.75rem;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    img {
      max-width: 100%;
      //margin-top: 2.2rem;
      //margin-bottom: 4.2rem;
    }

    ul:not(.p-listbox-list) {
      margin: 1rem 0;
      padding-left: 1rem;
    }

    h2 {
      color: var(--primary-color);
    }
  }

  .content ul:not(.p-galleria-indicator-list),
  .content ol:not(.p-galleria-indicator-list) {
    list-style: inherit;
  }

  .content img,
  .content iframe,
  .content .app-products {
    display: block;
    margin: auto;
  }
}

.layout-content {
  ul, ol {
    list-style: inherit;
  }
}

.layout-footer section {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

h1 {
  font-size: 2rem;
  line-height: 1.2;
}

.header-search {
  border-color: transparent;
  background-color: var(--primary-100);
}

.header-search:hover {
  border-color: var(--primary-color);
}

.header-search:focus {
  box-shadow: inset 0 0 0 1px var(--primary-color);
  border-color: var(--primary-color);
}

.button-payment {
  background-color: #fff;
  margin: 4px 2px;
  width: 160px;
  line-height: 60px;
  height: 60px;

  &:hover {
    background-color: #fff;
  }

  img {
    vertical-align: middle;
  }
}

@layer primevue {
  .p-paginator {
    justify-content: center;
  }

  .p-dataview
  {
    .p-dataview-header,
    .p-dataview-content {
      background-color: transparent;;
    }
  }

  .p-focus > .p-menuitem-content:hover {
    background: transparent !important;
  }

  .p-checkbox.p-highlight .p-checkbox-box {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
    border-color: var(--primary-color-hover);
    background: var(--primary-color-hover);
  }

  .p-progressbar .p-progressbar-label {
    color: var(--gray-900);
    padding-left: 0.5rem;
    white-space: nowrap !important;
    font-weight: 500;
    font-size: 1rem;
  }

  .p-progressbar-determinate .p-progressbar-value {
    justify-content: left !important;
    overflow: visible !important;
    background: var(--primary-600);
  }

  .p-galleria-inset-indicators .p-galleria-indicator-list {
    background: transparent !important;
  }

  .p-galleria-inset-indicators .p-galleria-indicator .p-galleria-indicator-button {
    background: rgba(0,0,0,.2) !important;
  }

  .p-galleria-inset-indicators .p-galleria-indicator-active .p-galleria-indicator-button {
    background: var(--primary-color) !important;
  }
}

.column-text-right {
  .p-column-header-content{
    text-align: right; // or center
    display: block !important;
  }
}

#form-registration input {
  width: 100% !important;
}

#static-page p {
  text-align: justify;
  text-justify: inter-word;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-galleria-thumbnail-item-content img {
  width: 100px;
  height: 100px;
}

.p-galleria .p-galleria-thumbnail-container {
  background: transparent;
  padding: 1rem 0.25rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  color: var(--primary-color);
}

.p-card-header img {

}

.zigzag {
  display: flex;
  flex-wrap: wrap;
}

/* Flex items (both <p> and <img> elements) */
.zigzag > * {
  flex: 1 1 50%;
  box-sizing: border-box;
  width: 50%;
}

.my-table tr td {
  text-align: left;
  border: 1px solid #e2e8f0;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1rem;
}

@media screen and (max-width: 768px) {
  .layout-header-wrapper {
    min-height: auto;
  }

  .layout-main > section {
    padding: 0 0.2rem;
  }

  .layout-main > section.content {
    padding: 0 1rem;
  }

  .layout-main > section:first-child {
    padding-top: 0.5rem;
  }

  .zigzag {
    flex-direction: column;
  }

  .zigzag > * {
    flex: 1 1 100%;
    width: inherit;
  }
}

.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}